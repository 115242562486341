import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, concatLatestFrom } from '@ngrx/effects';
import { map, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { SessionStorageService } from '../../../../core/services';
import { initTripCustomerFromStorage, tripCustomerChanged } from './trip-editor-customer.actions';
import {
  createNewTripSuccess,
  initializeNewTrip,
  initializeNewTripReservation,
  initializeNewTripReview,
  initializeNewTripVehicles,
} from '../create-trip/create-trip.actions';
import { clearStore } from '../../../../store/common/clear-store.actions';
import { selectTripCustomer } from './trip-editor-customer.selectors';

const storageKey = 'tripEditorCustomer';

@Injectable()
export class TripEditorCustomerEffects {
  public clearStorage$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(clearStore),
        tap(() => {
          this.storage.remove(storageKey);
        }),
      );
    },
    { dispatch: false },
  );

  public initNewTrip$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(initializeNewTrip),
        concatLatestFrom(() => this.store.select(selectTripCustomer)),
        tap(([, customer]) => {
          this.storage.set(storageKey, customer);
        }),
      );
    },
    { dispatch: false },
  );

  public initCustomerFromStorage$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(initializeNewTripVehicles, initializeNewTripReview, initializeNewTripReservation, createNewTripSuccess),
      )
      .pipe(
        map(() => {
          const value = this.storage.get(storageKey, true);
          const payload = value ? { value } : {};
          return initTripCustomerFromStorage(payload);
        }),
      );
  });

  public customerChanged$ = createEffect(
    () => {
      return this.actions$.pipe(ofType(tripCustomerChanged)).pipe(
        tap(({ value }) => {
          if (value) {
            this.storage.set(storageKey, value);
          } else {
            this.storage.remove(storageKey);
          }
        }),
      );
    },
    { dispatch: false },
  );

  constructor(private actions$: Actions, private storage: SessionStorageService, private store: Store) {}
}
