import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';

import { ApiService, SessionStorageService } from '../../../../core/services';
import {
  createNewTripSuccess,
  createTripVehiclesSaveStateToStorage,
  initializeNewTrip,
  initializeNewTripReservation,
  initializeNewTripReview,
  initializeNewTripVehicles,
  newTripItineraryChanged,
} from '../create-trip/create-trip.actions';
import * as fromActions from './trip-editor-quote.actions';
import * as fromSelectors from './trip-editor-quote.selectors';
import { FindCharterCatalogRequest, GetCharterTripQuoteRequest } from '@rootTypes/api';
import { TripQuotePriceCorrectors } from '@rootTypes';
import { selectTripEditorIds } from '../trip-editor-ids/trip-editor-ids.selectors';
import { selectTripEditorPriceCorrectors, selectTripEditorQuoteState } from './trip-editor-quote.selectors';
import {
  tripEditorReservationPaymentMethodChanged,
  tripEditorReservationPaymentOptionChanged,
} from '../trip-editor-payment/trip-editor-payment.actions';
import { selectTripEditorIsCardPayment } from '../trip-editor-payment/trip-editor-payment.selectors';
import { TripEditorQuoteState } from './types';
import { PopupService } from '../../../../core/popup/popup.service';

const storageKey = 'tripEditorQuote';

@Injectable()
export class TripEditorQuoteEffects {
  public initState$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(initializeNewTripVehicles, initializeNewTripReview, initializeNewTripReservation),
      map(({ tripItineraryId }) => {
        const state: TripEditorQuoteState = this.storage.get(storageKey, true);
        if (state?.tripItineraryId === tripItineraryId) {
          return fromActions.tripEditorQuoteInitStateFromStorage({ state });
        }
        return fromActions.tripEditorQuoteFindCatalogsRequested({ tripItineraryId });
      }),
    );
  });

  public findCatalogsRequested$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.tripEditorQuoteFindCatalogsRequested),
      switchMap(({ tripItineraryId, tripId }) => {
        const request: FindCharterCatalogRequest = { tripItineraryId };
        if (tripId) {
          request.tripId = tripId;
        }
        return this.api.findCharterCatalog(request).pipe(
          map((response) => {
            return fromActions.tripEditorQuoteFindCatalogsSuccess({ response, tripItineraryId });
          }),
          catchError((originalError) => {
            return of(
              fromActions.tripEditorQuoteFindCatalogsFailed({
                error: {
                  text: 'Failed to find charter catalog',
                  originalError,
                },
              }),
            );
          }),
        );
      }),
    );
  });

  public saveStateToStorage$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(createTripVehiclesSaveStateToStorage),
        concatLatestFrom(() => this.store.select(fromSelectors.selectTripEditorQuoteState)),
        tap(([, state]) => {
          this.storage.set(storageKey, state);
        }),
      );
    },
    { dispatch: false },
  );

  public clearState$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(initializeNewTrip, newTripItineraryChanged, createNewTripSuccess),
      map(() => fromActions.tripEditorQuoteRemoveStateFromStorage()),
    );
  });

  public removeStateFromStorage$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fromActions.tripEditorQuoteRemoveStateFromStorage),
        tap(() => {
          this.storage.remove(storageKey);
        }),
      );
    },
    { dispatch: false },
  );

  public addVehicleToTrip$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.addVehicleToTrip),
      concatLatestFrom(() => this.store.select(selectTripEditorQuoteState)),
      map(([{ selection }, state]) => {
        const newCount = (state.selectedCatalogs[selection.catalogId]?.count || 0) + selection.count;
        const catalog = state.catalogs.entity[selection.catalogId].data;
        if (newCount <= catalog.availabilityCount) {
          return fromActions.addVehicleToTripValid({ selection });
        }
        return fromActions.addVehicleToTripInvalid({ catalog });
      }),
    );
  });

  public addVehicleToTripInvalid$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fromActions.addVehicleToTripInvalid),
        tap(({ catalog }) => {
          this.popupService.openConfirmPopup({
            isHeaderCloseBtn: false,
            text: `The maximum number of ${catalog.displayName} vehicles cannot exceed ${catalog.availabilityCount}.`,
            confirmBtnText: 'OK',
          });
        }),
      );
    },
    { dispatch: false },
  );

  public onTripQuoteParamsChanged$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        fromActions.addVehicleToTripValid,
        fromActions.removeVehicleFromTrip,
        fromActions.vehicleCountChanged,
        tripEditorReservationPaymentOptionChanged,
        tripEditorReservationPaymentMethodChanged,
      ),
      concatLatestFrom(() => this.store.select(selectTripEditorPriceCorrectors)),
      switchMap(([, priceCorrectors]) => {
        return this.createGetTripQuoteRequestOnce(priceCorrectors).pipe(
          map((request) => {
            return fromActions.getTripQuoteRequested({ request });
          }),
        );
      }),
    );
  });

  public getTripQuoteRequested$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.getTripQuoteRequested),
      concatLatestFrom(() => this.store.select(selectTripEditorIds)),
      switchMap(([{ request }, { tripItineraryId }]) => {
        return this.api.getCharterTripQuote(request).pipe(
          map((tripQuote) => {
            return fromActions.getTripQuoteSuccess({ tripQuote, tripItineraryId });
          }),
          catchError((originalError) => {
            return of(
              fromActions.getTripQuoteFailed({
                error: {
                  text: 'Failed to calculate price summary',
                  originalError,
                },
              }),
            );
          }),
        );
      }),
    );
  });

  public onPriceCorrectorsChanged$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.tripEditorReservationEditPriceRequested),
      concatLatestFrom(() => this.store.select(selectTripEditorIds)),
      mergeMap(([{ priceCorrectors }, { tripItineraryId }]) => {
        return this.createGetTripQuoteRequestOnce(priceCorrectors).pipe(
          mergeMap((request) => {
            return this.api.getCharterTripQuote(request).pipe(
              map((tripQuote) => {
                return fromActions.tripEditorReservationEditPriceSuccess({ tripQuote, tripItineraryId });
              }),
              catchError((originalError) => {
                return of(
                  fromActions.tripEditorReservationEditPriceFailed({
                    error: {
                      text: 'Failed to calculate price summary',
                      originalError,
                    },
                  }),
                );
              }),
            );
          }),
        );
      }),
    );
  });

  constructor(
    private actions$: Actions,
    private storage: SessionStorageService,
    private store: Store,
    private api: ApiService,
    private popupService: PopupService,
  ) {}

  private createGetTripQuoteRequestOnce(
    priceCorrectors: TripQuotePriceCorrectors,
  ): Observable<GetCharterTripQuoteRequest> {
    return combineLatest([
      this.store.select(fromSelectors.selectSelectedCatalogs),
      this.store.select(selectTripEditorIsCardPayment),
      this.store.select(selectTripEditorIds),
    ]).pipe(
      take(1),
      map(([catalogs, isCardPayment, ids]) => {
        const correctors = priceCorrectors
          ? priceCorrectors
          : {
              discounts: [],
              additionalCharge: {
                predefined: [],
                custom: [],
              },
            };
        const request = {
          tripItineraryId: ids.tripItineraryId,
          catalogs,
          isCardPayment,
          ...correctors,
        } as GetCharterTripQuoteRequest;
        if (ids.tripId) {
          request.tripId = ids.tripId;
        }
        return request;
      }),
    );
  }
}
