import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ApiService } from '../../core/services';
import { getPortalEntityError, getPortalEntityRequestedIfAbsent, saveEntityData } from './actions';
import { getEntity } from './selectors';
import { bufferTime, catchError, filter, map, mergeMap } from 'rxjs/operators';
import { from, Observable, of } from 'rxjs';
import { CampusPortalEntity, PortalEntity, PortalEntityType, WpError } from '@rootTypes';

type EntityLoaders = {
  [EntityType in PortalEntityType]?: (entityId: string) => Observable<PortalEntity>;
};

const BUFFER_TIME = 500;

@Injectable()
export class EntitiesDataEffects {
  private entityLoaders: EntityLoaders;
  constructor(private actions$: Actions, private store: Store, private api: ApiService) {
    // Add loader functions to this map, to extend the entities we display in charter
    this.entityLoaders = {
      [PortalEntityType.CAMPUS]: (campusId: string) => {
        return this.api.getCampus({ campusId }).pipe(
          map((response) => {
            return {
              entityId: campusId,
              type: PortalEntityType.CAMPUS,
              districtId: response.campus.districtId,
              label: response.campus.name,
            } as CampusPortalEntity;
          }),
        );
      },
      [PortalEntityType.DISTRICT]: (districtId: string) => {
        return this.api.getDistrict({ districtId }).pipe(
          map((resp) => {
            return {
              entityId: districtId,
              type: PortalEntityType.DISTRICT,
              label: resp.district.name,
            };
          }),
        );
      },
      [PortalEntityType.CHARTER_TRIPS]: (tripId: string) => {
        return this.api.getCharterTrip({ tripId }).pipe(
          map((resp) => {
            return {
              entityId: tripId,
              type: PortalEntityType.CHARTER_TRIPS,
              label: resp.tripDisplayId,
            };
          }),
        );
      },
      [PortalEntityType.SCHOOL_EMPLOYEE]: (employeeId: string) => {
        return this.api.getSchoolEmployee({ schoolEmployeeId: employeeId }).pipe(
          map((resp) => {
            return {
              entityId: employeeId,
              type: PortalEntityType.SCHOOL_EMPLOYEE,
              label: resp.schoolEmployee.firstName + ' ' + resp.schoolEmployee.lastName,
            };
          }),
        );
      },
    };
  }

  public getPortalEntityRequestedIfAbsent$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getPortalEntityRequestedIfAbsent),
      // Load only, if the entity is missing from the store
      concatLatestFrom(({ entityId, entityType }) => this.store.select(getEntity(entityType, entityId))),
      filter(([_, existing]) => !existing),
      map(([action, _]) => action),
      // Accumulate requests and load only unique entities.
      // This is especially relevant for lists, where multiple requests for the same entity often fire at once
      bufferTime(BUFFER_TIME),
      mergeMap((buffered) => {
        const uniqueRequests = {};
        buffered.forEach(({ entityId, entityType }) => {
          uniqueRequests[`${entityId}${entityType}`] = { entityId, entityType };
        });
        return from(Object.values(uniqueRequests));
      }),
      // Perform the api call depending on entity type
      mergeMap(({ entityId, entityType }) => {
        return this.loadEntityByType$(entityId, entityType).pipe(
          map((entity) => saveEntityData({ entity })),
          catchError((originalError) => {
            console.error(originalError);
            const error: WpError = {
              originalError,
              text: 'Failed to load entity',
            };
            return of(getPortalEntityError({ entityId, entityType, error }));
          }),
        );
      }),
    );
  });

  private loadEntityByType$(entityId: string, entityType: PortalEntityType): Observable<PortalEntity> {
    if (!this.entityLoaders[entityType]) {
      console.error(`No loader found for type: ${entityType}`);
      return of(undefined);
    }
    return this.entityLoaders[entityType](entityId);
  }
}
