import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType, concatLatestFrom } from '@ngrx/effects';
import { map } from 'rxjs/operators';

import {
  initTripEditorReservationPayment,
  initTripEditorReservationPaymentFromContract,
} from './trip-editor-payment.actions';
import { selectTripEditorContract } from '../trip-editor-contract/trip-editor-contract.selectors';

@Injectable()
export class TripEditorPaymentEffects {
  public initStateFromContract$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(initTripEditorReservationPayment),
      concatLatestFrom(() => this.store.select(selectTripEditorContract)),
      map(([, contract]) => {
        return initTripEditorReservationPaymentFromContract({ contract });
      }),
    );
  });

  constructor(private store: Store, private actions$: Actions) {}
}
