import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType, concatLatestFrom } from '@ngrx/effects';
import { mergeMap, of, switchMap, tap } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';

import {
  checkAuthFirebaseUserNotAuthorized,
  checkAuthGetAccountFailed,
  checkAuthRequested,
  checkAuthSuccess,
  getCharterConfigFailed,
  getCharterConfigRequested,
  getCharterConfigSuccess,
  loginTokenNotFound,
  loginWithTokenFailed,
  loginWithTokenRequested,
  loginWithTokenSuccess,
  redirectToLoginOnRefreshSessionFailed,
} from './auth.actions';
import { WpError } from '@rootTypes';
import { AuthApiService } from '../../core/services/api/auth-api.service';
import { AppConfigErrorRouterService, HomeRouterService, tripsPath, UnauthorizedRouterService } from '../../routes';
import { UserRoleService } from '../../core/services/api/user-role.service';
import { isHomeInitialized } from './auth.selectors';
import { tripDetailsPath } from '../../routes/trip-details/trip-details-path';
import { ApiService } from '../../core/services';

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private api: ApiService,
    private authApi: AuthApiService,
    private homeRouter: HomeRouterService,
    private unauthorizedRouter: UnauthorizedRouterService,
    private appConfigErrorRouter: AppConfigErrorRouterService,
    private userRoleService: UserRoleService,
    private store: Store,
    private router: Router,
  ) {}

  public onLoginWithToken$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loginWithTokenRequested),
      switchMap(({ token, target }) => {
        if (!token) {
          return of(loginTokenNotFound());
        }
        return this.authApi.signInWithToken(token).pipe(
          map((userRole) => loginWithTokenSuccess({ userRole, target })),
          catchError((originalError) => {
            this.authApi.signOut();
            const error: WpError = {
              originalError,
              text: 'Failed to login with token',
            };
            return of(loginWithTokenFailed({ error }));
          }),
        );
      }),
    );
  });

  public onLoginTokenNotFound$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(loginTokenNotFound),
        tap(() => {
          this.authApi.redirectToPortalLogin();
        }),
      );
    },
    { dispatch: false },
  );

  public onLoginFailed$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(loginWithTokenFailed),
        tap(() => {
          this.unauthorizedRouter.navigate();
        }),
      );
    },
    { dispatch: false },
  );

  public onLoginSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(loginWithTokenSuccess),
        tap(({ userRole, target }) => {
          this.userRoleService.setUserRole(userRole);
          this.navigateToLandingPageAfterLogin(target);
        }),
      );
    },
    { dispatch: false },
  );

  public onHomeGuardCheckAuthRequested$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(checkAuthRequested),
      switchMap(() => {
        return this.authApi.checkFirebaseAuth().pipe(
          mergeMap((user) => {
            if (!user) {
              return of(
                checkAuthFirebaseUserNotAuthorized({
                  error: { text: 'Firebase user is not authorized' },
                }),
              );
            }
            return this.authApi.getAccount().pipe(
              map((account) => checkAuthSuccess({ account })),
              catchError((originalError) => {
                const error: WpError = {
                  originalError,
                  text: 'Failed to get user account',
                };
                return of(checkAuthGetAccountFailed({ error }));
              }),
            );
          }),
        );
      }),
    );
  });

  public onCheckAuthSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(checkAuthSuccess),
      map(() => getCharterConfigRequested()),
    );
  });

  public onCheckAuthFailed$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(checkAuthFirebaseUserNotAuthorized, checkAuthGetAccountFailed),
        tap(() => {
          this.authApi.redirectToPortalLogin(true);
        }),
      );
    },
    { dispatch: false },
  );

  public onCharterConfigRequested$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getCharterConfigRequested),
      switchMap(() => {
        return this.api.getCharterConfig({}).pipe(
          map((data) => getCharterConfigSuccess({ data })),
          catchError((originalError) => {
            const error = {
              originalError,
              text: 'Failed to load charter config',
            };
            return of(getCharterConfigFailed({ error }));
          }),
        );
      }),
    );
  });

  public onCharterConfigFailed$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(getCharterConfigFailed),
        tap(() => {
          this.appConfigErrorRouter.navigate();
        }),
      );
    },
    { dispatch: false },
  );

  public onRedirectToLoginOnRefreshSessionFailed$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(redirectToLoginOnRefreshSessionFailed),
        concatLatestFrom(() => this.store.select(isHomeInitialized)),
        filter(([, isHomeInitialized]) => isHomeInitialized),
        tap(() => {
          this.authApi.redirectToPortalLogin(true);
        }),
      );
    },
    { dispatch: false },
  );

  private navigateToLandingPageAfterLogin(target?: string): void {
    if (target) {
      const path = decodeURIComponent(target);
      if (path.startsWith(`/${tripsPath}`) || path.startsWith(`/${tripDetailsPath}`)) {
        this.router.navigateByUrl(path);
        return;
      }
      this.homeRouter.navigate();
    }
    this.homeRouter.navigate();
  }
}
