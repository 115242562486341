import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
  ChangeDetectorRef,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { ValidationErrors, UntypedFormControl, Validators } from '@angular/forms';

import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { Address } from '@rootTypes';
import { getAddressFromPlace } from '@rootTypes/utils';
import { FloatLabelType } from '@angular/material/form-field';

@Component({
  selector: 'wp-input-address',
  templateUrl: './input-address.component.html',
  styleUrls: ['./input-address.component.scss'],
})
export class InputAddressComponent implements AfterViewInit, OnInit, OnDestroy {
  @Input() public control: UntypedFormControl;
  @Input() public controlStateChange?: Observable<void>;
  /**
   * Floating strategy
   * @see https://material.angular.io/components/form-field/overview#floating-label
   */
  @Input() public floatLabel?: FloatLabelType = 'auto';
  /**
   * Floating label.
   * Will be hidden if omitted.
   */
  @Input() public label?: string;
  @Input() public placeholder?: string;

  @Input() public displayControl: UntypedFormControl = new UntypedFormControl();

  @Output() public valueChangedByUser = new EventEmitter<Address | null>();

  public required = false;

  @ViewChild('inputElement') private inputElementRef: ElementRef;
  private inputElement: HTMLInputElement;
  private mapsEventListener: google.maps.MapsEventListener;
  private pickedFromList = true;
  private subscriptions: Subscription[] = [];

  constructor(private cd: ChangeDetectorRef) {}

  public ngOnInit(): void {
    this.initControlValidators();
    this.initDisplayControl();
    if (this.controlStateChange) {
      const controlStateSub = this.controlStateChange.subscribe(() => {
        this.cd.detectChanges();
      });
      this.subscriptions.push(controlStateSub);
    }
  }

  public ngAfterViewInit(): void {
    this.initGoogleAutocomplete();
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
    if (this.mapsEventListener) {
      google.maps.event.removeListener(this.mapsEventListener);
    }
  }

  public onUserInput(): void {
    this.pickedFromList = false;
    if (this.control.pristine) {
      this.control.markAsDirty();
    }
    this.control.setValue(null);
    this.control.markAsTouched();
    this.valueChangedByUser.emit(null);
  }

  private getDisplayAddressFromGoogleAddress(addr: Address): string {
    return addr ? addr.formatted_address : null;
  }

  private initControlValidators(): void {
    let validators: ValidationErrors;
    if (this.control.validator) {
      validators = this.control.validator(this.control);
    }
    const newValidators = [this.validate.bind(this)];
    if (validators && validators.required) {
      newValidators.unshift(Validators.required);
      this.required = true;
    }
    this.control.setValidators(newValidators);
  }

  private initDisplayControl(): void {
    const initialDisplayValue = this.getDisplayAddressFromGoogleAddress(this.control.value);
    this.displayControl.setValue(initialDisplayValue, { emitEvent: false });

    if (this.control.disabled) {
      this.displayControl.disable();
    }
    const onControlDisabledChange = (disabled: boolean): void => {
      if (disabled) {
        this.displayControl.disable();
      } else {
        this.displayControl.enable();
      }
    };
    this.control.registerOnDisabledChange(onControlDisabledChange.bind(this));

    const controlValueSub = this.control.valueChanges
      .pipe(
        startWith(this.control.value),
        map((addr: Address) => {
          const displayAddress = this.getDisplayAddressFromGoogleAddress(addr);
          if (displayAddress) {
            this.displayControl.setValue(displayAddress, { emitEvent: false });
          }
        }),
      )
      .subscribe();
    this.subscriptions.push(controlValueSub);

    // We should toggle errors on displayControl manually,
    // otherwise material ErrorStateMatcher ignores them
    const controlStatusSub = this.control.statusChanges.subscribe((status) => {
      if (status === 'INVALID') {
        this.displayControl.setErrors(this.control.errors);
        this.displayControl.markAsTouched();
      } else {
        this.displayControl.setErrors(null);
      }
      this.cd.detectChanges();
    });
    this.subscriptions.push(controlStatusSub);
  }

  private initGoogleAutocomplete(): void {
    try {
      this.inputElement = this.inputElementRef.nativeElement;
      const options = {
        bounds: new google.maps.LatLngBounds(
          new google.maps.LatLng(37.06895739769219, -122.25214251093752),
          new google.maps.LatLng(37.85813042281522, -121.99396380000002),
        ),
      } as google.maps.places.SearchBoxOptions;
      const autocomplete = new google.maps.places.SearchBox(this.inputElement, options);
      const emitAddress = () => {
        const places: google.maps.places.PlaceResult[] = autocomplete.getPlaces();
        const bestGuess = places.find((place) => !!place?.address_components);
        if (!bestGuess) {
          console.error("Can't find address_components on selected address");
        }
        const address = bestGuess ? getAddressFromPlace(bestGuess) : null;
        this.pickedFromList = !!address;
        this.control.setValue(address);
        this.valueChangedByUser.emit(address);
        this.cd.detectChanges();
      };
      this.mapsEventListener = autocomplete.addListener('places_changed', emitAddress.bind(this));
    } catch (err) {
      console.error(err);
    }
  }

  private validate(): ValidationErrors | null {
    if (this.pickedFromList) {
      return null;
    }
    if (!this.displayControl.value) {
      return {
        required: true,
      };
    }
    return {
      notPickedFromList: true,
    };
  }
}
