import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType, concatLatestFrom } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';

import * as fromActions from './trip-editor-itinerary.actions';
import * as fromSelectors from './trip-editor-itinerary.selectors';
import { ApiService } from 'src/app/core/services';
import {
  initializeNewTripReservation,
  initializeNewTripReview,
  initializeNewTripVehicles,
} from '../create-trip/create-trip.actions';

@Injectable()
export class TripEditorItineraryEffects {
  public loadItineraryTrigger$ = createEffect(() => {
    return this.actions$
      .pipe(ofType(initializeNewTripVehicles, initializeNewTripReview, initializeNewTripReservation))
      .pipe(
        map(({ tripItineraryId }) => {
          return fromActions.tripEditorGetTripItineraryRequested({ tripItineraryId });
        }),
      );
  });

  public loadItineraryRequested$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.tripEditorGetTripItineraryRequested),
      concatLatestFrom(() => this.store.select(fromSelectors.selectTripEditorItinerary)),
      filter(([{ tripItineraryId }, cache]) => cache?.tripItineraryId !== tripItineraryId),
      switchMap(([{ tripItineraryId }]) => {
        return this.api.getCharterTripItinerary({ tripItineraryId }).pipe(
          map((response) => {
            return fromActions.tripEditorGetTripItinerarySuccess({ response });
          }),
          catchError((originalError) => {
            return of(
              fromActions.tripEditorGetTripItineraryFailed({
                error: {
                  text: 'Failed to load trip itinerary',
                  originalError,
                },
              }),
            );
          }),
        );
      }),
    );
  });

  constructor(private api: ApiService, private actions$: Actions, private store: Store) {}
}
